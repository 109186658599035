import Vue from 'vue'
const dayjs = require('dayjs')
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import utc from  'dayjs/plugin/utc'
import FileUploader from "@/components/blog/FileUploader";
dayjs.extend(utc)

const util = new (Vue.extend({
	methods: {
		formatDate(date) {
			if (date) return dayjs(date).format('MMMM D, YYYY')
			return null
		},

		toUtc(date) {
			return dayjs(date).utc()
		},

		formatDateTo(date, format) {
			if (!format) format = 'YYYY-MM-DD'
			if (date && format) return dayjs(date).utc().format(format)
			return null
		},

		formatPacificDate(date, showSpecifics = true) {
			if (date) {
				const isPST = dayjs(date).$d.toString().includes('Pacific Standard Time')
				let tz = isPST ? 'PST' : 'PDT'
				let gmtDiff = isPST ? 8 : 7
				return showSpecifics ? `${dayjs(date).subtract(gmtDiff, 'hour').format('MMM D, YYYY HH:mm')} ${tz}` : `${dayjs(date).subtract(gmtDiff, 'hour').format('MMMM D, YYYY')}`
			}
			return null
		},

		timeAgo(time, valueOnly) {
			if (time) return dayjs(time).fromNow(valueOnly)
			return null
		},

		dateDiff(date1, date2, unit) {
			const date = dayjs(date1)
			return date.diff(date2, unit)
		},

		getStartOfWeek(year, week){
			const date = new Date(year, 0, 1)
			if (week === 1) return date
			else {
				date.setDate(date.getDate() + 7 - date.getDay() + (week - 2)*7)
				return date.toDateString()
			}
		},
		isEmail(email) {
			// eslint-disable-next-line
			return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(email)
		},

		ensurePeriodEnd(text) {
			text = text.trim()
			if (!text.endsWith('.') && !text.endsWith('?') && !text.endsWith('!')) text = text + '.'
			return text
		},

		isProduction() {
			return process.env.NODE_ENV === 'production'
		},

		formatTime(dateTime) {
			if (dateTime) return dayjs(dateTime).format('h:mm A')
			return null
		},

		formatNumber(number) { // format with thousands separators
			return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},

		async uploadImage(e) {
			const files = e.target.files
			let imageId = null
			let imageBlogUri = null
			let imagePhotosUri = null
			let file = files[0]
			let image = await FileUploader.submitFile(file)
			let blockIds = []
			let currentFilePointer = 0
			let totalBytesRemaining = file.size
			let nextBlock = await FileUploader.getAndUploadNextBlock(file, blockIds, currentFilePointer, totalBytesRemaining, image.image_upload_uri)
			while (nextBlock !== null) {
				nextBlock = await FileUploader.getAndUploadNextBlock(file, blockIds, currentFilePointer, totalBytesRemaining, image.image_upload_uri)
			}
			let blockListCommited = await FileUploader.commitBlockList(file, blockIds, image.image_upload_uri)
			if (blockListCommited){
				let patched = await FileUploader.patchFile(image)
				if (patched) {
					imageId = image.blog_image_id
					imageBlogUri = image.image_download_uri
					if (util.isProduction()) {
						// This is a special case where when we upload images we can also access it through the images
						// url of cloudflare which is useful for images or videos that are used on the extension
						imagePhotosUri = 'https://momentum.photos/assets/' + imageBlogUri.split('/').at(-1)
					} else {
						imagePhotosUri = image.image_download_uri
					}
				} else {
					imageId = null
				}
			} else {
				imageId = null
			}
			return [imageId, imageBlogUri, imagePhotosUri]
		},

		async getDistinctId(userUuid) {
			if (!userUuid) return null
			// UUID hex -> int(bytes) in Uint8Array -> Uint8Array from digest -> Distinct ID in hex
			const byteArray = util.uuidToByteArray(userUuid)
			const digest = await crypto.subtle.digest('SHA-256', new Uint8Array(byteArray))
			return new Uint8Array(digest).reduce((hexDigest, byte) => {
				return hexDigest + byte.toString(16).padStart(2, '0')
			}, '')
		},

		uuidToByteArray: function (uuid) {
			let stripped = uuid?.replaceAll('-', '')
			if (!stripped) return null
			const byteArray = []
			while (stripped.length > 0) {
				let byte = stripped.slice(0, 2)
				stripped = stripped.slice(2)
				byteArray.push(parseInt(byte, 16))
			}
			return byteArray
		},
	},
}))()

export default util
