export default {
	userInfo: {
		email: null,
		display_name: 'Levi',
		full_name: '',
		loggedIn: false,
		when_validated: null
	},
	revenueMetrics: null,
	userMetrics: null,
	devMetrics: null,
	supportMetrics: null,
	contentMetrics: null,
	registeredMetrics: null,
	mantraMetrics: null,
	backgrounds: [],
	submissions: [],
	submissionsCount: 0,
	quotes: [],
	mantras: [],
	icons: [],
	queries: [],
	featureSets: [],
	featureList: [],
	activeQuoteFilter: 'newest',
	activeMantraFilter: 'newest',
	notifications: [],
	copiedNotification: null,
	todoProviders: [],
	todoIntegrations: [],
	metricIntegrations: [],
	activeIntegrationFilter: 'todo',
	activePromoFilter: 'coupons',
	availablePlans: [],
	promoBatches: [],
	coupons: [],
	experiments: [],
	users: [],
	teams: [],
	preview: {
		show: false,
		backgroundUrl: null,
		title: 'Hithadoo, Maldives',
		attribution: '© Nicolas Etienne / 500px'
	},
	campaigns: [],
	versions: [],
	notificationTargetAreas: [],
	campaignGroups: [],
}
