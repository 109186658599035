<template>
	<section class="detail user-detail" :class="{ 'nested': nestedDetail }">
		<h1>User</h1>
		<div class="detail-wrapper">
			<header>
				<h2 class="metric-stat">{{ user.name }}</h2>
				<h2 v-if="user.team_uuid" class="account-type"><router-link class="link-button" :to="`/team/${user.team_uuid}`">{{ user.user_type }}: {{ user.team_name }}</router-link></h2>
				<h2 v-else class="label account-type">{{ user.user_type }}</h2>

				<div class="row button-row">
					<button class="col button-secondary" v-if="posthogUrl"><a :href="posthogUrl">Posthog</a></button>
					<button class="col button-secondary"><router-link :to="'/users/' + activeId.toString() + '/user-activity'">User activity</router-link></button>
					<button class="col button-secondary"><router-link :to="'/users/' + activeId.toString() + '/transfer'">Transfer</router-link></button>
					<button class="col button-secondary"><router-link :to="'/users/' + activeId.toString() + '/cached-data'">Cached data</router-link></button>
					<button class="col button-secondary"><router-link :to="'/users/' + activeId.toString() + '/expire-token'">Expire token</router-link></button>
					<button class="col button-secondary"><router-link :to="{path: `/users/${activeId.toString()}/instrumentation-info`, query: { uuid: users[activeId] && users[activeId].user_uuid }}" >Instrumentation</router-link></button>
					<button v-if="user.deleted_date" class="col button-secondary"><router-link :to="'/users/' + activeId.toString() + '/undelete'">Undelete</router-link></button>
					<button class="col button-secondary hard-delete"><router-link :to="'/users/' + activeId.toString() + '/delete-user-request'">Hard delete</router-link></button>
				</div>

				<i class="detail-header-icon hide detail-hide" @click="closeView">✕</i>
			</header>

			<section class="blocks">
				<div class="block basic-info-block">
					<span class="title">Basic info</span>
					<ul>
						<li class="detail-row-notes">
							<div>
								<span class="label">Default Email</span>
								<i title="Copy to Clipboard" class="icon icon-copy ion-ios-copy-outline" aria-hidden="true" v-clipboard:copy="user.email" v-clipboard:success="onCopiedId.bind(this, 'emailCopied')"></i>
								<transition name="fade"><span v-if="emailCopied" class="success label"><i class="icon ion-checkmark"></i> Copied</span></transition>
								<a title="Search on Stripe" v-if="user.email" v-bind:href="'https://dashboard.stripe.com/search?query='+ encodeURIComponent(user.email)" target="_blank">
									<stripe-logo></stripe-logo>
								</a>
							</div>
							{{ user.email }}
							<span v-if="!!user.when_validated" title="Verified"><i class="fa fa-check verified"></i></span>
						</li>
						<li class="detail-row-notes">
							<div class="item-row">
								<span class="label">Other Emails</span>
								<ul>
									<li v-for="email in emails" :key="email.email">
										{{ email.email }}
										<span v-if="!!email.when_validated" title="Verified"><i class="fa fa-check verified"></i></span>
										<span v-if="email.deleted" class="deleted-date"> Deleted on {{ formatDate(email.deleted) }}</span>
									</li>
								</ul>
							</div>
						</li>
						<li class="detail-row-notes">
							<div class="item-row">
								<div class="label">Registered</div>
								{{ formatDate(user.created) }}
							</div>
						</li>
						<li v-if="user.deleted_date" class="detail-row-notes">
							<div class="label">Deleted</div>
							{{ formatDate(user.deleted_date) }}
						</li>
						<li class="detail-row-notes">
							<div class="item-row">
								<span class="label">ID</span>
								<i title="Copy to Clipboard" class="icon icon-copy ion-ios-copy-outline" v-clipboard:copy="activeId" v-clipboard:success="onCopiedId.bind(this, 'idCopied')"></i>
								<transition name="fade"><span v-if="idCopied" class="success label"><i class="icon ion-checkmark"></i> Copied</span></transition>
								<a title="Search on Stripe" v-if="activeId" v-bind:href="'https://dashboard.stripe.com/search?query='+ activeId" target="_blank">
									<stripe-logo></stripe-logo>
								</a>
							</div>
							{{ activeId }}
						</li>
						<li v-if="user.stripe_id" class="detail-row-notes">
							<div class="item-row">
								<span class="label">Stripe ID</span>
								<i title="Copy to Clipboard" class="icon icon-copy ion-ios-copy-outline" v-clipboard:copy="activeId" v-clipboard:success="onCopiedId.bind(this, 'stripeIdCopied')"></i>
								<transition name="fade"><span v-if="stripeIdCopied" class="success label"><i class="icon ion-checkmark"></i> Copied</span></transition>
								<a title="View on Stripe" v-if="user.stripe_id" v-bind:href="'https://dashboard.stripe.com/customers/'+ user.stripe_id" target="_blank">
									<stripe-logo></stripe-logo>
								</a>
							</div>
							{{ user.stripe_id }}
						</li>
						<li class="detail-row-notes">
							<div class="item-row">
								<span class="label">User Uuid</span>
								<i title="Copy to Clipboard" class="icon icon-copy ion-ios-copy-outline" aria-hidden="true" v-clipboard:copy="user.user_uuid" v-clipboard:success="onCopiedId.bind(this, 'uuidCopied')"></i>
								<transition name="fade"><span v-if="uuidCopied" class="success label"><i class="icon ion-checkmark"></i> Copied</span></transition>
							</div>
							{{ user.user_uuid }}
						</li>
						<li class="detail-row-notes" v-if="posthogDistinctId">
							<div class="item-row">
								<span class="label">Posthog Distinct Id</span>
								<i title="Copy to Clipboard" class="icon icon-copy ion-ios-copy-outline" aria-hidden="true" v-clipboard:copy="posthogDistinctId" v-clipboard:success="onCopiedId.bind(this, 'distinctIdCopied')"></i>
								<transition name="fade"><span v-if="distinctIdCopied" class="success label"><i class="icon ion-checkmark"></i> Copied</span></transition>
							</div>
							{{ posthogDistinctId }}
						</li>
						<li class="detail-row-notes">
							<div class="item-row">
								<div class="label">Collection</div>
								{{ user.nosql_db_collection_id }}
							</div>
						</li>
					</ul>

					<span class="title installation-info-title">Installation info</span>
					<transition name="fade">
						<div v-if="loadingInstallationInfo" class="loading">Loading...</div>
						<div v-else-if="!installationInfo" class="server-data-error">There is an issue loading installation info. Please try again.</div>
						<ul v-else>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Install uuid</div>
								</div>
								{{ installationInfo.client_uuid }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Browser info</div>
								</div>
								{{ installationInfo.user_agent }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Created</div>
								</div>
								{{ formatDate(installationInfo.client_created) }}
							</li>
							<li class="detail-row-notes">
								<div class="item-row">
									<div class="label">Link Created</div>
								</div>
								{{ formatDate(installationInfo.link_created) }}
							</li>
						</ul>
					</transition>
				</div>

				<div class="block">
					<span class="title">Feature info</span>
					<transition name="fade">
						<div v-if="loadingFeatureInfo" class="loading">Loading...</div>
						<div v-else-if="!loadingFeatureInfo && !featureSets && !enabledFeatures" class="server-data-error">There is a problem loading user feature info. Please try again.</div>
						<ul v-else>
							<li class="detail-row-notes">
								<span class="label">Feature sets</span>
								<table>
									<tr class="heading">
										<th>Name</th>
										<th>Created</th>
										<th>Expiry</th>
										<th>Revoked</th>
									</tr>

									<tr v-for="featureSet in featureSets" :key="featureSet.featureset_name" class="no-hover">
										<td>{{ featureSet.featureset_name }}</td>
										<td>{{ formatDate(featureSet.created) }}</td>
										<td>{{ formatDate(featureSet.expiry_date) }}</td>
										<td>{{ formatDate(featureSet.revoked_date) }}</td>
									</tr>
								</table>
							</li>

							<li class="detail-row-notes">
								<span class="label">Enabled features</span>
								<ul class="features-list">
									<li v-for="(feature, key) in enabledFeatures" :key="key" class="feature-alias">{{ feature.feature_alias }}</li>
								</ul>
							</li>
						</ul>
					</transition>
				</div>
				<div class="block" v-if="userExperiments.length">
					<span class="title">Experiments</span>
					<table>
						<tr class="heading">
							<th>Experiment</th>
							<th>Variant</th>
							<th>Active</th>
						</tr>
						<router-link class="experiment" tag="tr" v-for="userExperiment in userExperiments" :key="userExperiment.id" :to="`/experiments/${userExperiment.variant.experiment.id}`">
							<td>{{ userExperiment.variant.experiment.name }}</td>
							<td>{{ userExperiment.variant.name }}</td>
							<td>
								<span v-if="userExperiment.variant.experiment.active" title="Live" class="live"><i class="fa fa-check"></i></span>
								<span v-else><i class="fa fa-times"></i></span>
							</td>
						</router-link>
					</table>
				</div>
			</section>
		</div>

		<transition name="detail-nested">
			<router-view @closeNested="closeNested"></router-view>
		</transition>
	</section>
</template>



<script>
import Vue from 'vue'
import { mixin as VueFocusMixin } from 'vue-focus'
import StripeLogo from './StripeLogo'
import detailsMixins from '../../mixins/detailsMixins'
import util from "@/util";

export default {
	name: 'user-detail',
	store: ['users'],
	components: {
		StripeLogo
	},
	data() {
		return {
			emailCopied: false,
			idCopied: false,
			uuidCopied: false,
			distinctIdCopied: false,
			stripeIdCopied: false,
			emails:[],
			loadingFeatureInfo: false,
			featureSets: null,
			enabledFeatures: null,
			loadingInstallationInfo: false,
			installationInfo: null,
			posthogDistinctId: null,
			userExperiments: [],
		}
	},

	computed: {
		user() {
			return this.users[this.activeId] || {
				name: null
			}
		},
		expireLabel: function() {
			return this.expireInProgress ? 'Expiring...' : 'Expire Token'
		},
		flushLabel: function() {
			return this.flushInProgress ? 'Flushing...' : 'Flush Data'
		},
		posthogUrl() {
			if (!this.posthogDistinctId) return null
			return `${process.env.VUE_APP_POSTHOG_URI}person/${this.posthogDistinctId}`
		}
	},

	created() {
		this.checkNested()
		this.doSearch()
		this.getFeatureInfo()
		this.getInstallationInfo()
		this.getUserEnrolledExperiments()
	},

	mixins: [VueFocusMixin, detailsMixins],

	watch: {
		'$route' () {
			this.checkNested()
		},
		'user.user_uuid': {
			async handler(uuid) {
				this.posthogDistinctId = await util.getDistinctId(uuid)
			},
			immediate: true
		}
	},

	methods: {
		checkNested() {
			this.nestedDetail = this.$route.meta.viewType === 'nestedDetail'
			this.activeId = this.$route.params.id
			this.loadEmails(this.activeId)
		},

		loadEmails(id) {
			this.$http.get(`/users/${id}/emails`)
				.then(response => {
					this.emails = response.data.emails.filter(email => !email.is_default)
				})
				.catch(error => {
					console.log(error)
				})
		},
		doSearch() {
			let searchParams = {
				params: {
					input: this.activeId
				}
			}
			let requestParams = { timeout: 40000 }
			this.$http.get('/users/search', searchParams, requestParams)
				.then((response) => {
					this.foundUsers = response.data.users || []
					this.foundUsers.forEach((user) => {
						Vue.set(this.users, user.id, user)
					})
				})
				.catch(function(error) {
					console.log(error)
				})
		},
		onCopiedId(id) {
			if (id) this[id] = true
			setTimeout(() => {
				this[id] = false
			}, 1000)
		},

		closeNested() {
			window.location.href = '/#/users/' + this.activeId
		},

		getFeatureInfo() {
			this.loadingFeatureInfo = true
			if (!this.user || !this.user.user_uuid) return;
			const url = `/admin/${this.user.user_uuid}/feature-sets`
			this.$http.get(url)
				.then(res => {
					this.enabledFeatures = res.data.featuresEnabled
					this.featureSets = res.data.featureSets
				})
				.catch(error => console.log(`Error in GET ${ url }: ${ error }`))
				.finally(() => this.loadingFeatureInfo = false)
		},

		getInstallationInfo() {
			this.loadingInstallationInfo = true
			if (!this.user || !this.user.user_uuid) return;
			const url = `/admin/${this.user.user_uuid}/installation-info`
			this.$http.get(url)
				.then(res => this.installationInfo = res.data[0])
				.catch(error => console.log(`Error in GET ${ url }: ${ error }`))
				.finally(() => this.loadingInstallationInfo = false)
		},

		async getUserEnrolledExperiments() {
			try {
				const response = await this.$http.get(`/admin/experiment_manual_enrollments/?filter=userId%3D${this.user.user_uuid}`)
				this.userExperiments = response.data
			} catch (error) {
				console.error(error)
				this.error = error
			}
		},
	}
}
</script>



<style scoped>
	.basic-info-block { flex-basis: 20%; }

		.detail-row-notes:last-child { margin-bottom: 0; }

	.block .title.installation-info-title { margin-top: 2em; }

	.button-row { margin-bottom: 30px; }
		.button-secondary { width: 170px; }
		.button-secondary.hard-delete { border-color: tomato; }
			.button-secondary.hard-delete a { color: tomato; }

	.deleted-date { font-size: 0.8rem; font-weight: 700; color: red; opacity: 0.5; }
	.account-type { padding-left: 10px; }

	.features-list {  -webkit-columns: 2; -moz-columns: 2; columns: 2; }
		.feature-alias { margin-top: 10px; margin-bottom: 0; }

	.experiment-list {}
		.experiment { cursor: pointer; }

	.verified { color: var(--color-accent); }
</style>
